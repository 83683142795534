<template>
    <a-modal
            :title="id?'参数修改':'参数新增'"
            :visible="visible"
            width="30%"
            :confirm-loading="confirmLoading"
            ok-text="确认"
            cancel-text="取消"
            @ok="handleConfirm"
            @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="电梯类型" v-bind="formItemLayout">
                <a-select mode="multiple" v-decorator="['elevatorType',{rules: [{ required: true, message: '必选，请选择电梯类型' }]}]"
                          placeholder="请选择电梯类型">
                    <a-select-option v-for="item in elevator" :key="item.key"
                                     :value="item.key">{{item.val}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="参数类型" v-bind="formItemLayout">
                <a-select v-decorator="['categoryType',{rules: [{ required: true, message: '必选，请选择参数类型' }]}]"
                          placeholder="请选择参数类型">
                    <a-select-option v-for="it in category" :key="it.key"
                                     :value="it.key">{{it.val}}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="参数名称" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入参数名称"
                        v-decorator="['name',
            {rules: [{ required: true,max:20, message: '参数名称必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {add,update} from '@/api/paramer'
    export default {
        props:['visible'],
        data(){
            return {
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    }
                },
                maxLength:1000,
                confirmLoading: false,
                elevator:[
                    {val:'客梯',key:'0'},
                    {val:'货梯',key:'1'},
                    {val:'扶梯',key:'2'},
                    ],
                category:[
                    {val:'功能参数',key:0},
                    {val:'装潢参数',key:1},
                ],
                id:''
            }
        },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                }
            }
        },
        methods:{
            setFormValues(record) {
                Object.keys(record).forEach(key => {
                    this.form.getFieldDecorator(key);
                    let obj = {};
                    if(key == "id") {
                      this.id = record[key]
                    }else if(key == "elevatorType") {
                      obj[key] = record[key].split(',');
                      this.form.setFieldsValue(obj);
                    }else {
                        obj[key] = record[key];
                        this.form.setFieldsValue(obj);
                    }
                });
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    if(!err) {
                      values.elevatorType = values.elevatorType.join(',')
                        if (!this.id) {
                            add(values).then((res) => {
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                            values.id = this.id
                            update(values).then((res) => {
                                this.id = ''
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
                this.$emit('close')
                this.departmentSn=''
                this.form.resetFields()
            }
        }
    }
</script>

<style scoped>

</style>