const columns = [
    {
        title: '#',
        scopedSlots: { customRender: 'serial' }
    },
    {
        title: '参数名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '所属电梯类型',
        dataIndex: 'elevatorType',
        key: 'elevatorType',
        ellipsis: true,
        scopedSlots: { customRender: 'elevatorType' }
        // customRender: (text, row, index) => {
        //     return text==0 ? '客梯' : (text==1?'货梯':'扶梯');
        // },
    },
    {
        title: '所属参数类型',
        dataIndex: 'categoryType',
        key: 'categoryType',
        ellipsis: true,
        customRender: (text, row, index) => {
            return text ? '装潢 ' : '功能';
        },
    },
    {
        title: '创建时间',
        key: 'createTime',
        dataIndex: 'createTime',
    },
    {
        title: '操作',
        key: 'operation',
        fixed: 'right',
        width: 140,
        scopedSlots: { customRender: 'action' },
    },
];
export {
    columns
}
